import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./UserManagement.css";

import TitlePath from "../../../components/TitlePath/TitlePath";
import DashboardHeading from "../../../components/DashboardHeading/DashboardHeading";
import CustomTable from "../../../components/CustomTable/CustomTable";
import { fetchAllUsers  } from "../../../services/admin/adminService";

function AdminManagement() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Redux state
  const { users, loading, error } = useSelector((state) => state.admin);
  const data = useSelector((state) => state.admin);
console.log(data,users);
const admin=users||[]
  const [activeTab, setActiveTab] = useState("All");

  const columns = [
    { key: "id", label: "Admin ID" },
    { key: "name", label: "Name" },
    { key: "email", label: "Email" },
    { key: "role", label: "Role" },
    { key: "joinDate", label: "Join Date" },
    { key: "status", label: "Status", type: "status", sortable: true },
  ];

  // Fetch data when the component mounts
  useEffect(() => {
    dispatch(fetchAllUsers());
  }, [dispatch]);

  // Calculate counts for each tab
  const allCount = admin.length;
  const applicantCount = admin.filter((admin) => admin.role === "Applicant").length;
  const referrerCount = admin.filter((admin) => admin.role === "Referrer").length;

  const tabs = [
    { label: "All", count: allCount },
    { label: "Applicant", count: applicantCount },
    { label: "Referrer", count: referrerCount },
  ];

  // Filter admins based on active tab
  const filteredData =
    activeTab === "All"
      ? admin
      : admin.filter((admin) => admin.role === activeTab);


      console.log('Filtered Data:', filteredData);


  const handleBack = () => {
    navigate(`/admin_dashboard`);
  };

  const titlePath = {
    exactPath: `admin_management`,
    paths: [{ label: "Dashboard > ", onClick: handleBack }],
  };

  const headingDes = {
    heading: "Admin Management",
    des: "Effortlessly manage admins and their roles with organized details and quick actions.",
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      <div className="main-dashboard-container">
        <div>
          <TitlePath
            exactPath={titlePath.exactPath}
            paths={titlePath.paths}
            showNotification={true}
          />
          <DashboardHeading heading={headingDes.heading} des={headingDes.des} />
        </div>

        <div className="setting-page-container">
          <div className="dashboard-table-heading">
            <h4>Overview</h4>
          </div>

          <div className="sub-navbar">
            {tabs.map((tab) => (
              <div
                key={tab.label}
                className={`sub-navbar-item ${activeTab === tab.label ? "active" : ""}`}
                onClick={() => setActiveTab(tab.label)}
              >
                {tab.label} <span className="count">{tab.count}</span>
              </div>
            ))}
          </div>

          <div className="sub-setting-page-container blog-post-table-container">
          {/* {console.log('Filtered Data:', filteredData)}  */}
            <CustomTable
              columns={columns}
              data={[]} // Pass filtered data
              showActionColumn={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminManagement;
