import React from "react";
import "./ReferrerDashboard.css";
import CustomTable from "../../../components/CustomTable/CustomTable";
import addCircleIcon from "../../../assets/images/UserLayout/Icons/add_circle_icon.svg";
import { useNavigate } from "react-router-dom";

export default function ReferrerDashboard() {
  const columns = [
    { key: "id", label: "ID" },
    { key: "UserName", label: "User Name" },
    { key: "JobTitle", label: "Job Title" },
    { key: "Company", label: "Company" },
    { key: "SubmissionDate", label: "Submission Date" },
    { key: "status", label: "Status", type: "status", sortable: true },
  ];

  const data = [
    {
      id: "JR002",
      UserName: "Janet Lee",
      JobTitle: "Software Eng",
      Company: "VisionDigital",
      SubmissionDate: "12 Mar 2022",
      status: "Pending",
    },
    {
      id: "JR002",
      UserName: "Janet Lee",
      JobTitle: "Software Eng",
      Company: "VisionDigital",
      SubmissionDate: "12 Mar 2022",
      status: "Approved",
    },
    {
      id: "JR002",
      UserName: "Janet Lee",
      JobTitle: "Software Eng",
      Company: "VisionDigital",
      SubmissionDate: "12 Mar 2022",
      status: "Rejected",
    },
    {
      id: "JR002",
      UserName: "Janet Lee",
      JobTitle: "Software Eng",
      Company: "VisionDigital",
      SubmissionDate: "12 Mar 2022",
      status: "Processing",
    },
    {
      id: "JR002",
      UserName: "Janet Lee",
      JobTitle: "Software Eng",
      Company: "VisionDigital",
      SubmissionDate: "12 Mar 2022",
      status: "Pending",
    },
  ];

  const tabs = [
    { name: "All", count: 84 },
    { name: "Approved", count: 39 },
    { name: "Pending", count: 33 },
  ];
  const navigate = useNavigate();
  const handleRequestClick = () => {
    navigate("/user_dashboard/job_referrals/newrequest");
  };
  return (
    <div className="main-user-dashboard-table-container">
      <div className="user-dashboard-table-heading-container">
        <div>
          <h4 className="user-dashboard-table-main-heading">
            Referral Resumes
          </h4>
          <p className="user-dashboard-table-des">
            Find the best talented candidates for your company’s requirements.
          </p>
        </div>
      </div>
      <div className="user-dashboard-table-container">
        <CustomTable
          columns={columns}
          data={data}
          tabs={tabs}
          // setActiveTab={setActiveTab}
          // activeTab={activeTab}
        />
      </div>
    </div>
  );
}
