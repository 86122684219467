import React, { useState } from "react";
import "./CustomTable.css";
import FillerByIcon from "../../assets/images/adminLayout/Dashboard/Icons/FillerByIcon.svg";
import SortByIcon from "../../assets/images/adminLayout/Dashboard/Icons/SortByIcon.svg";
import doubleArrowRight from "../../assets/images/doubleArrowRight.svg";
import singleArrowRight from "../../assets/images/singleArrowRight.svg";

import filterby from "../../assets/images/filter-by.svg";
import sortby from "../../assets/images/sort-by.svg";
import Pagination from '../../components/Pagination/Pagination';

const CustomTable = ({ columns, data, setActiveTab, activeTab, tabs,handleEditClick, showActionColumn = true }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalPage = 12;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  // const paginate = (pageNumber) => {
  //   if (pageNumber > 0 && pageNumber <= totalPages) {
  //     setCurrentPage(pageNumber);
  //   }
  // };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderBadge = (text, type) => {
    const badgeClass = text.toLowerCase().replace(" ", "-");
    return <span className={`dt-badge ${type}-${badgeClass}`}>{text}</span>;
  };

  // const renderCell = (column, value) => {
  //   switch (column.type) {
  //     case "status":
  //       return renderBadge(value, "status");
  //     case "category":
  //       return renderBadge(value, "category");
  //     default:
  //       return value;
  //   }
  // };

  const renderCell = (column, value) => {
    if (column.key === "role") {
      const roleStyle = {
        color: value === "Applicant" ? "#3A37D6" : value === "Referrer" ? "#9747FF" : "inherit",
        fontWeight: "bold",
      };
      return <span style={roleStyle}>{value}</span>;
    }
  
    switch (column.type) {
      case "status":
        return renderBadge(value, "status");
      case "category":
        return renderBadge(value, "category");
      default:
        return value;
    }
  };
  

  const renderActionButtons = (id) => (
    <div className="dt-action-buttons">
      <button className="dt-action-btn" aria-label="Delete">
        <svg
          className="dt-icon delete"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path d="M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2" />
        </svg>
      </button>
      <button className="dt-action-btn" aria-label="Edit"onClick={() => handleEditClick(id)}>
        <svg
          className="dt-icon edit"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path d="M11 4H4a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2v-7" />
          <path d="M18.5 2.5a2.121 2.121 0 013 3L12 15l-4 1 1-4 9.5-9.5z" />
        </svg>
      </button>
    </div>
  );

  return (
    <div>
      {/* <div className="tabs">
        {tabs.map((tab) => (
          <button
            key={tab.name}
            className={`tab ${activeTab === tab.name ? "active" : ""}`}
            onClick={() => setActiveTab(tab.name)}
          >
            {tab.name} <span className="tab-count">{tab.count}</span>
          </button>
        ))}
      </div> */}

      {/* <div className="search-filter-container">
        <div>
          <i class="fa-solid fa-magnifying-glass"></i>
          <input className="table-search-input" placeholder="Search content" />
        </div>
        <div>
          <button className="filter-sort-button">
            <img src={FillerByIcon} className="filter-sort-button-icon" />
            Filter by
          </button>
          <button className="filter-sort-button">
            {" "}
            <img src={SortByIcon} className="filter-sort-button-icon" />
            Sort by
          </button>
        </div>
      </div> */}

      {/* user dashboard search filter */}
      <div className="search-filter-container user-dashboard-table-search-container">
        <div>
          <i class="fa-solid fa-magnifying-glass"></i>
          <input
            className="table-search-input user-table-search"
            placeholder="Search a content"
          />
        </div>
        {/* <h5 className="user-dashboard-table-heading">Application Details</h5> */}

        <div className="table-actions">
          <button className="filter-button">
            <img src={filterby} alt="Filter Icon" className="action-icon" />
            Filter by
          </button>
          <button className="sort-button">
            <img src={sortby} alt="Sort Icon" className="action-icon" />
            Sort by
          </button>
        </div>
      </div>

      <div className="dt-container">
        <table className="dt-table">
          <thead>
            <tr>
              <th className="dt-checkbox-cell">
                <input type="checkbox" />
              </th>
              {columns.map((column, index) => (
                <th key={index} className="dt-header-cell">
                  {column.label}
                  {column.sortable && <span className="dt-sort-icon">▼</span>}
                </th>
              ))}
              {/* {columns.some((col) => col.key === "action") ? null : (
                <th className="dt-header-cell">Action</th>
              )} */}

              {showActionColumn && <th className="dt-header-cell">Action</th>}
            </tr>
          </thead>
          <tbody>
            {currentItems.map((row, rowIndex) => (
              <tr key={rowIndex} className="dt-row">
                <td className="dt-checkbox-cell">
                  <input type="checkbox" />
                </td>
                {columns.map((column, colIndex) => (
                  <td key={colIndex} className="dt-cell">
                    {renderCell(column, row[column.key])}
                  </td>
                ))}
                {/* {columns.some((col) => col.key === "action") ? null : (
                  <td className="dt-cell">{renderActionButtons(row._id)}</td>
                )} */}

                {showActionColumn && (
                  <td className="dt-cell">{renderActionButtons(row._id)}</td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* pagination */}
      {/* {totalPages>1 &&<div className="pagination">
        <div className="previous-next-button-icon-container">
          <button
            className="pagination-arrow-button"
            onClick={() => paginate(1)}
            disabled={currentPage === 1}
          >
            <img src={doubleArrowRight} className="previous-next-button-img" />
          </button>
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className="pagination-arrow-button"
          >
            <img src={singleArrowRight} className="previous-next-button-img" />
          </button>
        </div>
        <div>
          {[...Array(totalPages).keys()].map((number) => (
            <button
              key={number + 1}
              onClick={() => paginate(number + 1)}
              className={number + 1 === currentPage ? "active" : ""}
            >
              {number + 1}
            </button>
          ))}
        </div>
        <div className="previous-next-button-icon-container">
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="pagination-arrow-button"
          >
            <img
              src={singleArrowRight}
              className="previous-next-button-img"
              style={{ transform: "scaleX(-1)" }}
            />
          </button>
          <button
            className="pagination-arrow-button"
            onClick={() => paginate(totalPages)}
            disabled={currentPage === totalPages}
          >
            <img
              src={doubleArrowRight}
              className="previous-next-button-img"
              style={{ transform: "scaleX(-1)" }}
            />
          </button>
        </div>
      </div>} */}

      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPage}
          paginate={paginate}
          singleArrowRight={singleArrowRight}
          doubleArrowRight={doubleArrowRight}
        />
      )}
    </div>
  );
};

export default CustomTable;
