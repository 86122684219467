import React, { useEffect, useState } from "react";
import CustomTable from "../../../../components/CustomTable/CustomTable";
import addCircleIcon from "../../../../assets/images/UserLayout/Icons/add_circle_icon.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchResumeReview } from "../../../../services/jobSeeker/jobSeekerService";

export default function UserResumeReviewTable() {
  const columns = [
    { key: "id", label: "ID" },
    { key: "UserName", label: "User Name" },
    { key: "CurrentJob", label: "Current Job" },
    { key: "CreatedDate", label: "Created Date" },
    { key: "status", label: "Status", type: "status", sortable: true },
  ];

  const [activeTab, setActiveTab] = useState("All");


  // const data = [
  //   {
  //     id: "RR002",
  //     UserName: "Janet Lee",
  //     CurrentJob: "Software Eng",
  //     CreatedDate: "12 Mar 2022",
  //     status: "Pending",
  //   },
  //   {
  //     id: "RR003",
  //     UserName: "Janet Lee",
  //     CurrentJob: "Software Eng",
  //     CreatedDate: "12 Mar 2022",
  //     status: "Approved",
  //   },
  //   {
  //     id: "RR004",
  //     UserName: "Janet Lee",
  //     CurrentJob: "Software Eng",
  //     CreatedDate: "12 Mar 2022",
  //     status: "Rejected",
  //   },
  //   {
  //     id: "RR005",
  //     UserName: "Janet Lee",
  //     CurrentJob: "Software Eng",
  //     CreatedDate: "12 Mar 2022",
  //     status: "Processing",
  //   },
  //   {
  //     id: "RR006",
  //     UserName: "Janet Lee",
  //     CurrentJob: "Software Eng",
  //     CreatedDate: "12 Mar 2022",
  //     status: "Pending",
  //   },
  // ];

  const dispatch = useDispatch();

  const resumeReview = useSelector((state) => state.jobSeeker.resumeReview); 

  useEffect(() => {
    dispatch(fetchResumeReview()); 
  }, [dispatch]);
  const handleEditClick = (id) => {
    navigate(`/user_dashboard/resume_review/${id}`);
  };
  console.log("Resume Review in Component:", resumeReview);

    // Map resume review to table data format
    const mappedData = resumeReview?.map((item) => ({
      id: item.index,
      _id: item.id,  // Use _id for edit action
      UserName: item.name,
      CurrentJob: item.currentJob,
      CreatedDate: new Date(item.submissionDate).toLocaleDateString(),
      status: item.currentStatus,
    }));

  const tabs = [
    { name: "All", count: 84 },
    { name: "Approved", count: 39 },
    { name: "Pending", count: 33 },
  ];
  const navigate = useNavigate();
  const handleRequestClick = () => {
    navigate("/user_dashboard/resume_review/newrequest");
  };
  return (
    <div className="main-user-dashboard-table-container">
      <div className="user-dashboard-table-heading-container">
        <div>
          <h4 className="user-dashboard-table-main-heading">
            Resume & Cover Letter Review & Assistance
          </h4>
          <p className="user-dashboard-table-des">
            Get expert feedback on your resume and cover letter to improve your
            chances of landing the job you want. Submit a request and receive
            personalized assistance.
          </p>
        </div>
        <div>
          <button
            className="button-common user-dashboard-table-button"
            onClick={handleRequestClick}
          >
            <img
              src={addCircleIcon}
              className="user-dashboard-table-button-icon"
            />
            Request Resume Review
          </button>
        </div>
      </div>
      <div className="user-dashboard-table-container">
        <CustomTable
          columns={columns}
          data={mappedData}
          tabs={tabs}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          handleEditClick={handleEditClick}

        />
      </div>
    </div>
  );
}
