import React from 'react'
import './UserProfile.css'
import { useNavigate } from "react-router-dom";
import TitlePath from "../../../components/TitlePath/TitlePath";
import arrowBack from "../../../assets/images/adminLayout/Dashboard/Icons/arrowBack.svg";
import chatIcon from "../../../assets/images/adminLayout/Dashboard/Icons/chatIcon.svg";
import UserIcon from "../../../assets/images/adminLayout/Dashboard/Icons/user-icon-01.svg";

function UserProfile() {

    const navigate = useNavigate();

    const handleBack = () => {
        navigate(`/admin_dashboard`);
      };

    const titlePath = {
        exactPath: `user_management`,
        paths: [{ label: "Dashboard > ", onClick: handleBack }],
      };


  return (
    <div className="main-dashboard-container">

        

        <div className='title-place'>
       <TitlePath exactPath={titlePath.exactPath} paths={titlePath.paths}/>
       </div>

       <div className='user-layouts-02'>

            <div className="jra-header">
                    <div className="jra-header-title">
                            
                            <img src={arrowBack} className="jra-header-title-icon" />
                            <h4>
                            User Management -{" "}
                            <span className="green-heading">Amelia Clarke - JR45845</span>
                            </h4>
                            
                    </div>


                    <div className="jra-header-actions">
                            
                            <button className="jra-chat-button">
                            <img src={chatIcon} className="chatIcon" />
                            Chat
                            <span className="jra-notification-badge">15</span>
                            </button>
                    </div>
            </div>


            <div className='user-full-detailes'>

                    <h5 className="user-information-heading">Application Status</h5>


                    <div className="jra-status-row">
                        <div className='user-image-place'>
                            <img className='userImage-class' src={UserIcon} />
                            <div>
                            <p className="jra-status-label">Name</p>
                            <p className="jra-status-value">Amelia Clarke</p>
                            </div>
                        </div>
                    <div>
                <div>
                <p className="jra-status-label">Submission Date</p>
                <p className="jra-status-value">2024-07-30</p>
                </div>
              
              </div>

              </div>



              <h5 className="user-information-heading">User Information</h5>

                    <div className="jra-grid-2-col">

                    <div>
                        <p className="jra-status-label">Date Of Birth</p>
                        <p className="jra-status-value">2024-07-30 </p>
                    </div>
                    <div>
                        <p className="jra-status-label">E-Mail Address</p>
                        <p className="jra-status-value">Amelia.Clarke@gmail.com</p>
                    </div>
                    <div>
                        <p className="jra-status-label">Phone Number</p>
                        <p className="jra-status-value">077 123 4567</p>
                    </div>

                    <div>
                        <p className="jra-status-label">Role</p>
                        <p className="jra-status-value">Referrer</p>
                    </div>
                    </div>
                    
            </div>

            


       </div>

     
      
    </div>
  )
}

export default UserProfile
