import DashboardIcon from "../../../assets/images/adminLayout/sidebar/DashboardIcon.svg";
import DocumentIcon from "../../../assets/images/adminLayout/sidebar/DocumentIcon.svg";
import FileIcon from "../../../assets/images/adminLayout/sidebar/FileIcon.svg";
import FolderIcon from "../../../assets/images/adminLayout/sidebar/FolderIcon.svg";
import MessageIcon from "../../../assets/images/adminLayout/sidebar/MessageIcon.svg";
import SettingIcon from "../../../assets/images/adminLayout/sidebar/SettingIcon.svg";
import UserIcon from "../../../assets/images/adminLayout/sidebar/UserIcon.svg";
import UserSymbole from "../../../assets/images/adminLayout/sidebar/User-Icon.svg";
const sidebar = [
  {
    title: "Dashboard",
    path: "/admin_dashboard/",
    icon: DashboardIcon,
  },
  {
    title: "Service Management",
    path: "/admin_dashboard/service_management",
    icon: UserIcon,
    children: [
      {
        title: "Job Referrals",
        path: "/admin_dashboard/service_management",
        icon: DocumentIcon,
      },
      {
        title: "Career Advice",
        path: "/admin_dashboard/career_advice_table",
        icon: DocumentIcon,
      },
      {
        title: "Resume Review",
        path: "/admin_dashboard/resume_review",
        icon: DocumentIcon,
      },
    ],
  },

  {
    title: "User Manangement",
    path: "/admin_dashboard/user_management",
    icon: UserSymbole,
  },
  {
    title: "Discussion Forum",
    path: "/admin_dashboard/discussion_forum",
    icon: FileIcon,
  },

  {
    title: "Learning Hub",
    path: "/admin_dashboard/learning_hub",
    icon: FileIcon,
  },
  {
    title: "Payment Processing",
    path: "/admin_dashboard/payment_processing",
    icon: FolderIcon,
  },
  {
    title: "Chat Manager",
    path: "/admin_dashboard/chat_manager",
    icon: MessageIcon,
  },
  {
    title: "Settings",
    path: "/admin_dashboard/settings",
    icon: SettingIcon,
  },
];

export default sidebar;
