import DashboardIcon from "../../../assets/images/adminLayout/sidebar/DashboardIcon.svg";
import DocumentIcon from "../../../assets/images/adminLayout/sidebar/DocumentIcon.svg";
import FileIcon from "../../../assets/images/adminLayout/sidebar/FileIcon.svg";
import FolderIcon from "../../../assets/images/adminLayout/sidebar/FolderIcon.svg";
import MessageIcon from "../../../assets/images/adminLayout/sidebar/MessageIcon.svg";
import SettingIcon from "../../../assets/images/adminLayout/sidebar/SettingIcon.svg";
import UserIcon from "../../../assets/images/adminLayout/sidebar/UserIcon.svg";
const topbar = [
  {
    title: "Dashboard",
    path: "/user_dashboard",
  },
  
  {
    title: "Job Referrals",
    path: "/user_dashboard/job_referrals",
  },

  // {
  //   title: "Career Advice",
  //   path: "/user_dashboard/learning_hub",
  // },
  {
    title: "Resume Review",
    path: "/user_dashboard/resume_review",
  },
  // {
  //   title: "Messenger",
  //   path: "/user_dashboard/chat_manager",
  // },
  {
    title: "Settings",
    path: "/user_dashboard/settings",
  },
];

export default topbar;
