import React, { useState } from "react";
import FileUpload from "../../../../components/FileUpload/FileUpload";
// import "..//JobReferralsNewRequest.css";
import '../../JobReferrals/JobReferralsNewRequest/JobReferralsNewRequest'
import questionCircle from "../../../../assets/images/UserLayout/Icons/question-circle.svg";
import creditCard from "../../../../assets/images/UserLayout/Icons/credit_card.svg";
import closeIcon from "../../../../assets/images/UserLayout/Icons/close.svg";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addResumeReviewRequest } from "../../../../services/jobSeeker/jobSeekerService";

const ResumeReviewNewRequest = () => {
  const [resume, setResume] = useState(null);
  const [coverLetter, setCoverLetter] = useState(null);
  const userData = JSON.parse(localStorage.getItem("user"));

  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [formData, setFormData] = useState({
    name: userData.user.personalInfo.firstName+'\u00A0'+userData.user.personalInfo.lastName,
    email: userData.user.email,
    mobile: userData.user.mobile,
    industry: "",
    desiredPosition: "",
    desiredCompany:"",
    currentJob:"",
    paymentMethod: "CreditCard",
    paidDate: new Date().toISOString(),
    paidAmount: "500",
  });

  const handleResumeUpload = (file) => {
    console.log("Resume Uploaded:", file);
    setResume(file);
  };

  const handleCoverLetterUpload = (file) => {
    console.log("Cover Letter Uploaded:", file);
    setCoverLetter(file);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

 const handleSubmit = async (e) => {
  e.preventDefault();

  const submissionData={
    ...formData,
    resume:resume || null,
    coverLetter:coverLetter|| null
  }
  console.log(submissionData);
  
  const response = await dispatch(addResumeReviewRequest(submissionData));
  console.log(response.data.statusCode);

  if (response?.data?.statusCode === 200 && response?.data?.data?._id) {
    navigate(`/user_dashboard/resume_review/${response?.data?.data?._id}`);
  }

}

  const handleCancel = () => {
    navigate("/user_dashboard/resume_review");
  };

  return (
    <div className="user-job-referral-form-request-container">
      <h4>New Resume Review</h4>
      <p>Please fill in the information below to start the resume review process.</p>

      <form className="user-job-referral-form-request" onSubmit={handleSubmit}>
        <div>
          <h5>User Information Form</h5>
          <div className="user-job-referral-form-group">
            <div className="sub-user-job-referral-form-group">
              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  className="form-input"
                  placeholder="Enter your full name"
                  required
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  E-Mail Address<span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="email"
                  className="form-input"
                  placeholder="Enter your email address"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            <div className="user-job-referral-form-input-container">
              <label className="form-label">
                Your Phone Number<span className="required">*</span>
              </label>
              <input
                type="text"
                name="mobile"
                className="form-input"
                placeholder="Enter your phone number"
                value={formData.mobile}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
        </div>

        <div>
          <h5>Industry Selection</h5>
          <div className="user-job-referral-form-group">
            <div className="sub-user-job-referral-form-group">
              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  Select Your Industry<span className="required">*</span>
                </label>
                <select 
                  name="industry" 
                  class="form-input" 
                  value={formData.industry}
                  onChange={handleInputChange} 
                  required>
                  <option value="" disabled selected>
                  Choose the industry that matches your desired position
                  </option>
                  <option value="company1">Option 1</option>
                  <option value="company2">Option 2</option>
                </select>
              </div>
              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  Desired Position<span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="desiredPosition"
                  className="form-input"
                  value={formData.desiredPosition}
                  onChange={handleInputChange}
                  placeholder="Enter the URL of your desired position"
                  required
                />
              </div>
            </div>

            <div className="sub-user-job-referral-form-group">
              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  Select Desired Company<span className="required">*</span>
                </label>
                <select 
                name="desiredCompany" 
                class="form-input" 
                value={formData.desiredCompany}
                onChange={handleInputChange}
                required>
                  <option value="" disabled selected>
                  Choose a desired company
                  </option>
                  <option value="company1">Option 1</option>
                  <option value="company2">Option 2</option>
                </select>
              </div>
              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  Current Job<span className="required">*</span>
                </label>
                <select 
                name="currentJob" 
                class="form-input" 
                required
                value={formData.currentJob}
                onChange={handleInputChange}
                >
                  <option value="" disabled selected>
                  Choose a desired company
                  </option>
                  <option value="company1">Option 1</option>
                  <option value="company2">Option 2</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="upload-section">
            <h5>Upload Section</h5>
            <FileUpload
              fileHeading="Upload Your Resume"
              uploadDes="Drag and Drop or Choose your resume to upload"
              onFileSelect={handleResumeUpload}
              isRequired={true}
            />
          </div>

          <div className="upload-section">
          <FileUpload
              fileHeading="Upload Your Cover Letter (Optional)"
              uploadDes="Drag and Drop or Choose your cover letter to upload"
              onFileSelect={handleCoverLetterUpload}
              isRequired={false}
            />
            <p className="upload-des">
              <img src={questionCircle} className="question-circle-icon" />
              Don’t have a Resume or Cover Letter? Click Here
            </p>
          </div>

          {/* <div className="upload-section">
            <p className="resume-file-heading">Description </p>
            <textarea
              className="contact-form-textarea user-request-form"
              placeholder="Enter your description here..."
              rows="10"
            />
          </div> */}
        </div>
        <div className="user-request-form-actions">
          <button className="button-common user-dashboard-table-button pay-cancel-button" onClick={handleCancel}>
            <img src={closeIcon} class="user-dashboard-table-button-icon" />
            Cancel
          </button>
          <button className="button-common user-dashboard-table-button">
            <img src={creditCard} class="user-dashboard-table-button-icon" />
            Pay $500
          </button>
        </div>
      </form>
    </div>
  );
};

export default ResumeReviewNewRequest;
