
import {
  CREATE_REFERRER_SUCCESS,
  CREATE_REFERRER_FAILURE,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_FAILURE,
  GET_JOB_REFERRAL_BY_ID,
  GET_RESUME_REVIEW_BY_ID,
  GET_JOB_REFERRALS,
  GET_RESUME_REVIEW,
  UPDATE_JOB_SEEKER_FEEDBACK,
  UPDATE_RESUME_REVIWE_FEEDBACK,
  CREATE_JOB_REFERRAL_REQUEST,
  ACCOUNT_VERIFICATION_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  RESEND_OTP_SUCCESS,
  RESEND_OTP_FAILURE,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILURE,
  CREATE_PASSWORD_SUCCESS,
  CREATE_PASSWORD_FAILURE,
  FORGOT_PASSWORD_SEND_OTP_SUCCESS,
  FORGOT_PASSWORD_SEND_OTP_FAILURE,
  FORGOT_PASSWORD_VERIFY_OTP_SUCCESS,
  FORGOT_PASSWORD_VERIFY_OTP_FAILURE,
  FORGOT_PASSWORD_CREATE_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_CREATE_PASSWORD_FAILURE,
} from "../actions/referrerAction";

const initialState = {
  referrer: [],
  selectedReferrer:null,
  resumeReview:[],
  selectedResumeReview:null,
  dashboard:null,
  isAuthenticated: false,
  error: null,
  loading: false,
  otpSent: false,
  passwordReset: false,
  referrer_verification:null
};

// Authentication Reducer
const referrerReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_REFERRER_SUCCESS:
      return {
        ...state,
        referrer: action.payload,
        isAuthenticated: true,
        error: null,
      };

    case CREATE_REFERRER_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

      case FETCH_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboard: action.payload,
        error: null,
      };

    case FETCH_DASHBOARD_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case GET_JOB_REFERRAL_BY_ID:
      return {
        ...state,
        selectedReferrer: action.payload,
      };

      case GET_JOB_REFERRALS:
        return {
            ...state,
            referrer: action.payload,
        };
        case GET_RESUME_REVIEW:
        return {
            ...state,
            resumeReview: action.payload,
        };
    case UPDATE_JOB_SEEKER_FEEDBACK:
      return {
        ...state,
        referrer: state.referrer.data.map((referrer) =>
          referrer._id === action.payload._id ? { ...referrer, feedback: action.payload.feedback } : referrer
        ),
      };
      case GET_RESUME_REVIEW_BY_ID:
      return {
        ...state,
        selectedResumeReview: action.payload,
      };
      case UPDATE_RESUME_REVIWE_FEEDBACK:
      return {
        ...state,
        resumeReview: state.resumeReview.data.map((resumeReview) =>
          resumeReview._id === action.payload._id ? { ...resumeReview, feedback: action.payload.feedback } : resumeReview
        ),
      };
      
    case CREATE_JOB_REFERRAL_REQUEST:
      return {
          ...state,
          referrer: [...state.referrer, action.payload],
      };
    case ACCOUNT_VERIFICATION_REQUEST:
      return {
          ...state,
          referrer_verification: action.payload,
      };
      
    case LOGIN_SUCCESS:
      return {
        ...state,
        referrer: action.payload,
        isAuthenticated: true,
        error: null,
      };

    case LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case RESEND_OTP_SUCCESS:
      return {
        ...state,
        otpSent: true,
        error: null,
      };

    case RESEND_OTP_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        otpSent: false,
        error: null,
      };

    case VERIFY_OTP_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case CREATE_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordReset: true,
        error: null,
      };

    case CREATE_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case FORGOT_PASSWORD_SEND_OTP_SUCCESS:
      return {
        ...state,
        otpSent: true,
        error: null,
      };

    case FORGOT_PASSWORD_SEND_OTP_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case FORGOT_PASSWORD_VERIFY_OTP_SUCCESS:
      return {
        ...state,
        otpSent: false,
        error: null,
      };

    case FORGOT_PASSWORD_VERIFY_OTP_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case FORGOT_PASSWORD_CREATE_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordReset: true,
        error: null,
      };

    case FORGOT_PASSWORD_CREATE_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default referrerReducer;
