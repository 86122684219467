import React from "react";
import "./LearningHubFilter.css";
import searchIcon from "../../assets/images/searchIcon.svg";
import dropdownIcon from "../../assets/images/Learninghub-drop-down-arro.svg";


const LearningHubFilter = () => {
  return (
    <div className="main-learninghub-filter">
      <h4 className="learninghub-filter-heading">Refine your search</h4>
      <p className="learninghub-filter-des">
        Find the resources you need by filtering through our webinars, articles,
        and quizzes. Select the category that match your interests and learning
        goals.
      </p>
      <div className="learninghub-filter-form-container">
        {/* <select className="learninghub-filter-form-input">
          <option value="" disabled selected>
            Category
          </option>
          <option value="category1">Category 1</option>
          <option value="category2">Category 2</option>
          <option value="category3">Category 3</option>
        </select>

        <input
          type="date"
          id="date-input"
          className="learninghub-filter-form-input  learninghub-filter-date"
          placeholder="Date"
        />
        <label className="date-label" htmlFor="date-input">
          Date
        </label> */}
        {/* <span className="date-icon">&#x1F4C5;</span> */}

        <div className="learninghub-filter-dropdown-container">
            <select className="learninghub-filter-form-input">
              <option value="" disabled selected>
                Popularity
              </option>
              <option value="mostPopular">Most Popular</option>
              <option value="highToLow">High to Low</option>
              <option value="lowToHigh">Low to High</option>
            </select>
            <img
              src={dropdownIcon}
              alt="Dropdown Arrow"
              className="learninghub-dropdown-icon"
            />
          </div>


        {/* <select className="learninghub-filter-form-input">
          <option value="" disabled selected>
            Popularity
          </option>
          <option value="mostPopular">Most Popular</option>
          <option value="highToLow">High to Low</option>
          <option value="lowToHigh">Low to High</option>
        </select> */}
        <div className="learninghub-search-container">
          <img
            className="learninghub-search-icon"
            src={searchIcon}
            alt="search icon"
          />
          <input
            className="learninghub-filter-form-input learninghub-search"
            placeholder="Search"
          />
        </div>
      </div>
    </div>
  );
};

export default LearningHubFilter;
