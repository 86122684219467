import React from 'react'
import './User.css'
import { useNavigate } from "react-router-dom";
import TitlePath from "../../../components/TitlePath/TitlePath";
import arrowBack from "../../../assets/images/adminLayout/Dashboard/Icons/arrowBack.svg";
import chatIcon from "../../../assets/images/adminLayout/Dashboard/Icons/chatIcon.svg";
import resumeIcon from "../../../assets/images/adminLayout/Dashboard/Icons/resumeIcon.svg";
import UserIcon from "../../../assets/images/adminLayout/Dashboard/Icons/user-icon-01.svg";
function User() {


    const navigate = useNavigate();

    const handleBack = () => {
        navigate(`/admin_dashboard`);
      };

    const titlePath = {
        exactPath: `user_management`,
        paths: [{ label: "Dashboard > ", onClick: handleBack }],
      };
  return (
    <div className="main-dashboard-container">
        <div className='title-place'>
       <TitlePath exactPath={titlePath.exactPath} paths={titlePath.paths}/>
       </div>

        <div className='user-layouts'>
            <div className="jra-header">
                <div className="jra-header-title">
                    
                    <img src={arrowBack} className="jra-header-title-icon" />
                    <h4>
                    User Management -{" "}
                    <span className="green-heading">Amelia Clarke - JR45845</span>
                    </h4>
                </div>
                <div className="jra-header-actions">
                    <select className="jra-status-select">
                    <option>Mark Status</option>
                    </select>
                    <button className="jra-chat-button">
                    <img src={chatIcon} className="chatIcon" />
                    Chat
                    <span className="jra-notification-badge">15</span>
                    </button>
                </div>
                </div>

                <div className='user-full-detailes'>

                    <h5 className="user-information-heading">Application Status</h5>
                    <div className="jra-status-row">
                        <div>
                            <p className="jra-status-label">Name</p>
                            <p className="jra-status-value">Amelia Clarke</p>
                        </div>
                    <div>
                <div>
                <p className="jra-status-label">Submission Date</p>
                <p className="jra-status-value">2024-07-30</p>
                </div>
              <div>
                <p className="jra-status-label">Status</p>
                <span className="pending-status-badge">Pending</span>
              </div>
            </div>
          </div>

          <h5 className="user-information-heading">User Information</h5>

          <div className="jra-grid-2-col">

            <div>
              <p className="jra-status-label">Date Of Birth</p>
              <p className="jra-status-value">2024-07-30 </p>
            </div>
            <div>
              <p className="jra-status-label">E-Mail Address</p>
              <p className="jra-status-value">Amelia.Clarke@gmail.com</p>
            </div>
            <div>
              <p className="jra-status-label">Phone Number</p>
              <p className="jra-status-value">077 123 4567</p>
            </div>
          </div>

          <h5 className="user-information-heading">Company Details</h5>

          <div className="jra-grid-2-col">
            <div>
              <p className="jra-status-label">Company Name</p>
              <p className="jra-status-value">XYZ Corp</p>
            </div>
            <div>
              <p className="jra-status-label">Address</p>
              <p className="jra-status-value">Amelia Clarke@gmail.com</p>
            </div>
            <div>
              <p className="jra-status-label">Website</p>
              <p className="jra-status-value">XYZcorp.com</p>
            </div>
            <div>
              <p className="jra-status-label">Contact Number</p>
              <p className="jra-status-value">
              077 123 4567
              </p>
            </div>

            

          </div>

          <h5 className="user-information-heading">Current Position</h5>

            <div className="jra-grid-2-col">
            <div>
              <p className="jra-status-label">Referrer's Current Position</p>
              <p className="jra-status-value">Manager</p>
            </div>
            </div>




            <h5 className="user-information-heading">Identity Verification</h5>

            <div className="jra-grid-2-col">
            <div>
              <p className="jra-status-label">Submitted Information:</p>
              <p className="jra-status-value">User Image Upload</p>
            </div>
            </div>


            <div className="jra-grid-2-col">
            <div>
              <p className="jra-status-label"></p>
              <a href="#" className="jra-file-link ">
                {/* <span className="jra-file-icon">📄</span> */}
                <img src={UserIcon} />
                Amelia Clarke
              </a>
            </div>
            <div>
              <p className="jra-status-label">Passport</p>
              <a href="#" className="jra-file-link ">
                {/* <span className="jra-file-icon">📄</span> */}
                <img src={resumeIcon} />
                ABCorpDid.pdf
              </a>
            </div>
          </div>

          </div>

        </div>


      
    </div>
  )
}

export default User
