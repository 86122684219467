import React, { useState } from "react";
import "./Services.css";
import ServicesIcon from "../../assets/images/cv-png.svg";
import CustomButton from "../../components/CustomButton/CustomButton";
import GetStartArrow from "../../assets/images/Get-start-arrow.svg";
import SignInModal from "../../components/SignInModal/SignInModal";
import { useNavigate } from "react-router-dom";

const Services = () => {
  const navigate=useNavigate();
  const [menuOpen, setMenuOpen] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoginModal, setIsLoginModal] = useState(false);
  const [isCreatePassword, setIsCreatePassword] = useState(false);
  const [otpVarification, setOtpVarification] = useState(false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setIsLoginModal(false);
    setIsCreatePassword(false);
    setOtpVarification(false);
    setOtp(false);
    setIsForgotPassword(false);
  };
  const isAuthenticated = localStorage.getItem("authToken");

  const handleLoginClick = () => {
    if(isAuthenticated){
      navigate('/user_dashboard/resume_review/newrequest')
    }
    else{
    setIsLoginModal(true);
    setIsModalOpen(true);
    setIsForgotPassword(false);
  }
  };
  const ServicesData = [
    // {
    //   heading: "Services",
    //   des: "Get personalised guidance on your career path from experienced professionals. Connect with current employees who can offer valuable insights on career building, interview skills, and navigating the job market for your target industry.",
    // },
    {
      heading: "Receive Resume & Cover Letter Assistance",
      des: "Get your application materials polished and ready to impress. Konect connects you with professionals who can review your resume and cover letter, ensuring they showcase your skills and experience effectively.",
    },
    // {
    //   heading: "Provide Career Advice",
    //   des: "Get paid to share your knowledge and expertise with job seekers on Konect. Help others achieve their career goals by offering guidance on navigating the job search process.",
    // },
    // {
    //   heading: "Provide Resume & Cover Letter Assistance",
    //   des: "Help job seekers land their dream jobs by offering resume and cover letter writing assistance on Konect. Leverage your skills to provide valuable feedback and ensure their application materials stand out.",
    // },
  ];
  return (
    <div className="main-services-container">
      <SignInModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        onClose={closeModal}
        setIsLoginModal={setIsLoginModal}
        isLoginModal={isLoginModal}
        isCreatePassword={isCreatePassword}
        setIsCreatePassword={setIsCreatePassword}
        otpVarification={otpVarification}
        setOtpVarification={setOtpVarification}
        otp={otp}
        setOtp={setOtp}
        isForgotPassword={isForgotPassword}
        setIsForgotPassword={setIsForgotPassword}
      />
      <h1 className="services-heading">
       Get Your Free
        <span className="heading-different-color"> Resume Review</span>
      </h1>
      <div className="services-heading-des">
        Discover how Konect can help you advance your career with personalized
        support and expert guidance
      </div>
      <div className="services-container">
        {ServicesData.map((data) => (
          <div className="sub-services-container">
            <div>
              <img
                className="services-icon-image"
                src={ServicesIcon}
                alt="services-icon-image"
              />

              <div className="services-sub-heading">{data.heading}</div>
              <div className="services-des">{data.des}</div>
              <CustomButton
                // label="Learn More →"
                label={
                  <span className="get-button-aliment">
                    Learn More <img src={GetStartArrow} alt="arrow" className="get-button-icon" />
                  </span>
                }
                onClick={handleLoginClick}
                type="services-green-border"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
