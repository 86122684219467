
// Authentication Action Types
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
export const CREATE_ADMIN_SUCCESS = 'CREATE_ADMIN_SUCCESS';
export const CREATE_ADMIN_FAILURE = 'CREATE_ADMIN_FAILURE';
export const FETCH_DASHBOARD_SUCCESS = 'FETCH_DASHBOARD_SUCCESS';
export const FETCH_DASHBOARD_FAILURE = 'FETCH_DASHBOARD_FAILURE';
export const GET_JOB_REFERRAL_BY_ID = 'GET_JOB_REFERRAL_BY_ID';
export const GET_RESUME_REVIEW_BY_ID = 'GET_RESUME_REVIEW_BY_ID';
export const GET_JOB_REFERRALS = "GET_JOB_REFERRALS";
export const GET_RESUME_REVIEW = "GET_RESUME_REVIEW";
export const UPDATE_JOB_SEEKER_FEEDBACK = 'UPDATE_JOB_SEEKER_FEEDBACK';
export const UPDATE_RESUME_REVIWE_FEEDBACK = 'UPDATE_RESUME_REVIWE_FEEDBACK';
export const CREATE_JOB_REFERRAL_REQUEST = 'CREATE_JOB_REFERRAL_REQUEST';
export const CREATE_RESUME_REVIEW_REQUEST = 'CREATE_JOB_REFERRAL_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const RESEND_OTP_SUCCESS = 'RESEND_OTP_SUCCESS';
export const RESEND_OTP_FAILURE = 'RESEND_OTP_FAILURE';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAILURE = 'VERIFY_OTP_FAILURE';
export const CREATE_PASSWORD_SUCCESS = 'CREATE_PASSWORD_SUCCESS';
export const CREATE_PASSWORD_FAILURE = 'CREATE_PASSWORD_FAILURE';
export const FORGOT_PASSWORD_SEND_OTP_SUCCESS = 'FORGOT_PASSWORD_SEND_OTP_SUCCESS';
export const FORGOT_PASSWORD_SEND_OTP_FAILURE = 'FORGOT_PASSWORD_SEND_OTP_FAILURE';
export const FORGOT_PASSWORD_VERIFY_OTP_SUCCESS = 'FORGOT_PASSWORD_VERIFY_OTP_SUCCESS';
export const FORGOT_PASSWORD_VERIFY_OTP_FAILURE = 'FORGOT_PASSWORD_VERIFY_OTP_FAILURE';
export const FORGOT_PASSWORD_CREATE_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_CREATE_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_CREATE_PASSWORD_FAILURE = 'FORGOT_PASSWORD_CREATE_PASSWORD_FAILURE';


export const fetchUsersSuccess = (users) => ({
  type: FETCH_USERS_SUCCESS,
  payload: users,
});

export const fetchUsersFailure = (error) => ({
  type: FETCH_USERS_FAILURE,
  payload: error,
});


// Create ADMIN Success
export const createAdminSuccess = (admin) => ({
    type: CREATE_ADMIN_SUCCESS,
    payload: admin,
  });
  
  // Create User Failure
  export const createAdminFailure = (error) => ({
    type: CREATE_ADMIN_FAILURE,
    payload: error,
  });

  // Create Dashboard Success
export const fetchDashboardSuccess = (admin) => ({
  type: FETCH_DASHBOARD_SUCCESS,
  payload: admin,
});

// Create Dashboard Failure
export const fetchDashboarsdFailure = (error) => ({
  type: FETCH_DASHBOARD_FAILURE,
  payload: error,
});

// Get Job Referral By Id 
export const getJobReferralById = (jobReferral) => ({
  type: GET_JOB_REFERRAL_BY_ID,
  payload: jobReferral,
});

// Update Job Seeker Feedback
export const updateAdminFeedback = (adminFeedback) => ({
  type: UPDATE_JOB_SEEKER_FEEDBACK,
  payload: adminFeedback,
});

// Get Job Referrals
export const getJobReferrals = (jobReferrals) => ({
  type: GET_JOB_REFERRALS,
  payload: jobReferrals,
});

// Get Resume Review By Id 
export const getResumeReviewById = (resumeReview) => ({
  type: GET_RESUME_REVIEW_BY_ID,
  payload: resumeReview,
});

// Create Job Referral Request
export const createJobReferralRequest = (jobReferral) => ({
  type: CREATE_JOB_REFERRAL_REQUEST,
  payload: jobReferral,
});

// Create Job Referral Request
export const createResumeReviewRequest = (resumeReview) => ({
  type: CREATE_RESUME_REVIEW_REQUEST,
  payload: resumeReview,
});

// Update Resume Review Feedback
export const updateResumeReviewFeedback = (resumeReviewFeedback) => ({
  type: UPDATE_RESUME_REVIWE_FEEDBACK,
  payload: resumeReviewFeedback,
});

// Get Resume Review
export const getResumeReview = (resumeReview) => ({
  type: GET_RESUME_REVIEW,
  payload: resumeReview,
});
  // Login Success
  export const loginSuccess = (admin) => ({
    type: LOGIN_SUCCESS,
    payload: admin,
  });
  
  // Login Failure
  export const loginFailure = (error) => ({
    type: LOGIN_FAILURE,
    payload: error,
  });
  
  // Resend OTP Success
  export const resendOTPSuccess = () => ({
    type: RESEND_OTP_SUCCESS,
  });
  
  // Resend OTP Failure
  export const resendOTPFailure = (error) => ({
    type: RESEND_OTP_FAILURE,
    payload: error,
  });
  
  // Verify OTP Success
  export const verifyOTPSuccess = () => ({
    type: VERIFY_OTP_SUCCESS,
  });
  
  // Verify OTP Failure
  export const verifyOTPFailure = (error) => ({
    type: VERIFY_OTP_FAILURE,
    payload: error,
  });
  
  // Create Password Success
  export const createPasswordSuccess = () => ({
    type: CREATE_PASSWORD_SUCCESS,
  });
  
  // Create Password Failure
  export const createPasswordFailure = (error) => ({
    type: CREATE_PASSWORD_FAILURE,
    payload: error,
  });
  
  // Forgot Password Send OTP Success
  export const forgotPasswordSendOTPSuccess = () => ({
    type: FORGOT_PASSWORD_SEND_OTP_SUCCESS,
  });
  
  // Forgot Password Send OTP Failure
  export const forgotPasswordSendOTPFailure = (error) => ({
    type: FORGOT_PASSWORD_SEND_OTP_FAILURE,
    payload: error,
  });
  
  // Forgot Password Verify OTP Success
  export const forgotPasswordVerifyOTPSuccess = () => ({
    type: FORGOT_PASSWORD_VERIFY_OTP_SUCCESS,
  });
  
  // Forgot Password Verify OTP Failure
  export const forgotPasswordVerifyOTPFailure = (error) => ({
    type: FORGOT_PASSWORD_VERIFY_OTP_FAILURE,
    payload: error,
  });
  
  // Forgot Password Create Password Success
  export const forgotPasswordCreatePasswordSuccess = () => ({
    type: FORGOT_PASSWORD_CREATE_PASSWORD_SUCCESS,
  });
  
  // Forgot Password Create Password Failure
  export const forgotPasswordCreatePasswordFailure = (error) => ({
    type: FORGOT_PASSWORD_CREATE_PASSWORD_FAILURE,
    payload: error,
  });
  
